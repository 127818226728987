<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="row">
                <div class="col-md-3 mb-2 ">
                    <div class="pd-t-5 pd-b-5">
                        <h1 class="pd-0 mg-0 tx-20 text-overflow">Pengajuan Progress Kemajuan</h1>
                    </div>
                    <div class="breadcrumb pd-0 mg-0">
                        <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i><span class="breadcrumb-item active"> Data Kemajuan Mahasiswa</span></a>
                    </div>
                </div>
                <div class="col-md-9 mt-2 ">
                    <div class="row">
                        <p class="col-md-3"><i style="width:20px" class="btn btn-sm m-0 p-0 btn-secondary mr-1">1</i>Seminar I</p>
                        <p class="col-md-3"><i style="width:20px" class="btn btn-sm m-0 p-0 btn-primary mr-1">2</i>Seminar II</p>
                        <p class="col-md-3"><i style="width:20px" class="btn btn-sm m-0 p-0 btn-success mr-1">3</i>Sidang Tesis</p>
                    </div>
                </div>
            </div>
            
        </div> 
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Pengajuan Progress
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <input v-model="form.id_tahapan" type="hidden">
                            <input v-model="form.id_kemajuan" type="hidden">
                            <div class="form-group">
                                <label>Tanggal<span class="text-danger">*</span></label>
                                <datetime-picker required="" v-model="form.tanggal" :config="{timeZone: 'Asia/Jakarta'}"></datetime-picker>
                            </div>
                            <!--
                            <div class="form-group">
                                <label>No Surat Undangan<span class="text-danger">*</span></label>
                                <input class="form-control" v-model="form.no_surat" required="" type="text" placeholder="255/ITI...">
                            </div>
                            <hr>
                            <div class="alert alert-danger">
                                <b>PERHATIAN !!!</b><br>
                                - Jika ada perubahan tanggal pelaksanaan, silahkan pilih kembali tanggal<br>
                                - Pengajuan yang disetujui <br>
                                - Mahasiswa akan mendapatkan surat undangan yang di generate oleh sistem
                            </div>
                            <hr><br><br>
                            -->
                            <div class="form-group">
                                <label>Surat Undangan<span class="text-danger">*</span></label>
                                <input ref="fileInput" @change="uploadFile" type="file" accept="application/pdf" value="" class="form-control" required="" placeholder="">
                            </div>
                            <hr>
                            <div class="alert alert-info">
                                <b>Keterangan</b><br>
                                - File yang didukung: <b>pdf </b><br>
                                - Maksimal file : <b>5 Mb</b>
                            </div>
                            
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-info">Setujui</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <modal name="my-lulus" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Penyelesaian Progress
                        </h5>
                    </div>
                    <form @submit.prevent="submitLulus" enctype="multipart/form-data">
                        <div class="card-body">
                            <input v-model="lulus.id_tahapan" type="hidden">
                            <input v-model="lulus.id_kemajuan" type="hidden">
                            <div class="form-group">
                                <label>Berita Acara<span class="text-danger">*</span></label>
                                <input ref="fileInputLulus" @change="uploadFileLulus" type="file" accept="application/pdf" value="" class="form-control" required="" placeholder="">
                            </div>
                            <hr>
                            <div class="alert alert-info">
                                <b>Keterangan</b><br>
                                - File yang didukung: <b>pdf</b><br>
                                - Maksimal file : <b>5 Mb</b>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_lulus()">Batal</button>
                                <button type="submit" class="btn btn-success">Lulus</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <modal name="my-sertifikat" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Upload Surat Pernyataan
                        </h5>
                    </div>
                    <form @submit.prevent="submitSertifikat" enctype="multipart/form-data">
                        <div class="card-body">
                            <input v-model="lulus.id_tahapan" type="hidden">
                            <input v-model="lulus.id_kemajuan" type="hidden">
                            <div class="form-group">
                                <label>Surat Pernyataan<span class="text-danger">*</span></label>
                                <input ref="fileInputSertifikat" @change="uploadFileSertifikat" type="file" accept="application/pdf" value="" class="form-control" required="" placeholder="">
                            </div>
                            <hr>
                            <div class="alert alert-info">
                                <b>Keterangan</b><br>
                                - File yang didukung: <b>pdf</b><br>
                                - Maksimal file : <b>5 Mb</b>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_sertifikat()">Batal</button>
                                <button type="submit" class="btn btn-success">Upload</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="col-md-4 pl-0 pr-0">
                                    <div class="input-group input-group-flush input-group-merge">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Mahasiswa
                                            </div>
                                        </div>
                                        <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Mahasiswa...">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-search"></span>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                    </div>
                                </div>
                                <div class="ml-2 col-md-3 pl-0 pr-0">
                                    <select @change.prevent="getData()" v-model="filterPengutamaan" class="form-control">
                                        <option value="">- Semua Pengutamaan -</option>
                                        <option v-for="(item, index) in dataPengutamaan" :key="index" :value="item.id">{{ item.nama_pengutamaan }}</option>
                                    </select>
                                </div>
                                <div class="ml-2 col-md-3 pl-0">
                                    <select @change.prevent="getData()" v-model="filterTahapan" class="form-control">
                                        <option value="">- Tahapan Progress -</option>
                                        <option v-for="(item, index) in dataTahapan" :key="index" :value="item.id">{{ item.nama_kegiatan }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-xl table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>NAMA/NIM</th>
                                            <th>Judul</th>
                                            <th>Tanggal Pengajuan</th>
                                            <th>Pengajuan Tahapan</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataPengguna.length > 0">
                                        <tr v-for="(user, index) in dataPengguna" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>
                                                {{ user.name }}<br>
                                                {{ user.nim }} | <span class="pt-0 pb-0 btn btn-sm btn-oblong btn-outline-info">{{ user.pengutamaan }}</span>
                                            </td>
                                            <td>
                                                <div style="width:350px;white-space: normal;">
                                                {{ user.judul }}
                                                </div>
                                            </td>
                                            <td>
                                                {{ convertDate(user.tanggal) }}
                                                <br>
                                                <span v-if="user.permohonan != null">
                                                <i  class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/permohonan/' + user.permohonan" target="_blank" style="cursor:pointer" class="text-primary">Surat Permohonan<i class="fa fa-external-link ml-2"></i></a>
                                                <br>
                                                </span>
                                                
                                                <span v-if="user.pernyataan == 1">
                                                <i  class="fa fa-file-o mr-2"></i><a style="cursor:pointer" :href="printUrl + 'Home/surat_pernyataan_magister/PZ4l987CHwBK0f8Ttp9K4WCThtQDkGgPNQhRYUDhuhOC6QV4Sv/' + user.id_detail" target="_blank" class="text-primary">Surat Pernyataan<i class="fa fa-external-link ml-2"></i></a>
                                                <br>
                                                </span>
                                                
                                                <span v-if="user.undangan != null" span>
                                                <!-- <i class="fa fa-file-o mr-2"></i><a style="cursor:pointer" class="text-primary" @mouseover="updateUndangan(user.id_detail)" @click="generateUndangan">Surat Undangan<i class="fa fa-external-link ml-2"></i></a> -->
                                                <i class="fa fa-file-o mr-2"></i><a :href="$apiconfig + 'uploads/undangan/' + user.undangan" target="_blank" style="cursor:pointer" class="text-primary" >Surat Undangan<i class="fa fa-external-link ml-2"></i></a>
                                                </span>
                                            </td>
                                            <td>
                                                <button v-if="user.id_tahapan == 1" disabled class="btn btn-sm btn-oblong btn-warning btn-block mg-b-10">{{ user.tahapan }}</button>
                                                <button v-if="user.id_tahapan == 2" disabled class="btn btn-sm btn-oblong btn-primary btn-block mg-b-10">{{ user.tahapan }}</button>
                                                <button v-if="user.id_tahapan == 3" disabled class="btn btn-sm btn-oblong btn-succes btn-block mg-b-10">{{ user.tahapan }}</button>
                                            </td>
                                            <td>
                                                <button v-if="user.status == 0" type="button" class="btn btn-sm btn-oblong btn-outline-info btn-block mg-b-10" @click="updateProgress(user.id_tahapan, user.id, user.tanggal)">Setujui</button>
                                                <button v-if="user.status == 4" type="button" class="btn btn-sm btn-oblong btn-outline-success btn-block mg-b-10" @click="updateLulus(user.id_tahapan, user.id)">Disetujui</button>
                                                <button v-if="user.status == 2" type="button" class="btn btn-sm btn-oblong btn-secondary btn-block mg-b-10" @click="updateSertifikat(user.id_tahapan, user.id)">Sudah Lulus</button>
                                                <button v-if="user.status == 4 || user.status == 0" type="button" class="btn btn-sm btn-oblong btn-danger btn-block mg-b-10" @click.prevent="postDelete(user.id_detail)">Hapus</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataPengguna.length == 0">
                                        <tr>
                                            <td colspan="6" class="text-center">--- Tidak Ada Pengajuan ---</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'datapendaftar_'"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                        <div class="card-body pb-0">
                            <!-- Asal Pendaftar -->
                            <div class="d-block mb-4">
                                <div class="h6 d-block mb-4 text-center font-weight-bold pt-2 text-dark">SURAT PERNYATAAN PENYELESAIAN STUDI MAGISTER</div>
                                <div class="row">
                                    <div class="col-4"><a style="font-size:14px">Yang bertanda tangan dibawah ini</a><a class="float-right">:</a></div>
                                    <div class="col-8"></div>
                                </div>

                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Nama</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.fullname }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">NIM</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.username }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Prodi/Fakultas/Sekolah</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">Doktor Teknik Sipil - FTSL</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Judul Disertasi</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.judul }}</a></div>
                                </div>
                                    
                                <div class="row mt-3">
                                    <div class="col-4"><a style="font-size:14px">Pembimbing</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.pembimbing }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Lama Studi Berjalan</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.studi_berjalan != '' ? print.studi_berjalan : '-' }} Semester</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Tahapan Lulus</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">Tahapan {{ print.id_tahapan+1 }} ({{ print.tahapan }})</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Batas Penyelesaian </a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.batas }}</a></div>
                                </div>

                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Dengan ini saya menyatakan bahwa apabila sampai dengan batas waktu tersebut 
                                    ({{ print.batas }}) belum melakukan {{ print.tahapan }} maka saya dianggap mengundurkan diri sebagai mahasiswa 
                                    Institut Teknologi Bandung.</a></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12"><a style="font-size:14px">Prodi Magister dan Doktor Teknik Sipil tidak akan mentolerir perpanjangan 
                                    masa studi berikutnya dengan alasan apapun.</a></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12"><a style="font-size:14px">Demikian surat pernyataan ini saya buat dengan sebenarnya dan dalam keadaan 
                                    sadar tanpa ada paksaan dari pihak manapun.</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Bandung, {{ onlyDate(print.tanggal) }}</a></div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Yang membuat pernyataan,</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Materai 10000</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">{{ print.fullname }}</a></div>
                                    <div class="col-12"><a style="font-size:14px">NIM {{ print.username }}</a></div>
                                </div>

                                    <div class="d-block mb-1 mt-1 text-center"><a style="font-size:14px">Mengetahui,</a></div>

                                <div class="row mt-3">
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Dosen Wali</a></div>
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Ketua Program Studi,</a></div>
                                </div>
                                <br><br><br><br>
                                <div class="row mt-3">
                                    <div class="col-6"><a class="text-center" style="font-size:14px">{{ print.pembimbing }}</a></div>
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Dr.-Ing. Ir. Ediansjah Zulkifli, S.T., M.T.</a></div>
                                </div>
                            </div>
                        </div>
                </section>
        </vue-html2pdf>  

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'undangan_'"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="html2PdfUndangan"
            >
                <section slot="pdf-content">
                        <div class="card-body pb-0">
                            <!-- Asal Pendaftar -->
                            <div class="d-block mb-4">
                                <div class="row">
                                    <div class="col-12">
                                    <b><a class="text-dark" style="font-size:17px">Program Studi Magister dan <br> Doktor Teknik Sipil</a></b>
                                    <br>
                                    <a style="font-size:12px">Jalan Ganesha No. 10 Bandung 40132
                                    <br>Telp: +6222 2506445
                                    <br>Fax: +6222 2506445
                                    </a>
                                    </div>
                                </div>
                                <br>

                                <div class="row mt-1">
                                    <div class="col-2"><a style="font-size:14px">No.</a><a class="float-right">:</a></div>
                                    <div class="col-7"><a class="text-dark" style="font-size:14px">{{ undangan.undangan }}</a></div>
                                    <div class="col-3"><a class="text-dark" style="font-size:14px;text-align:right;float:right">{{ onlyDate(undangan.tgl_undangan) }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-2"><a style="font-size:14px">Lampiran</a><a class="float-right">:</a></div>
                                    <div class="col-10"><a class="text-dark" style="font-size:14px">1 Set</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-2"><a style="font-size:14px">Hal</a><a class="float-right">:</a></div>
                                    <div class="col-10"><a class="text-dark" style="font-size:14px">Seminar {{ undangan.id_tahapan }}</a></div>
                                </div>

                                <br>

                                <table border="1" class="col-12">
                                    <tr>
                                        <th>
                                            <div class="h6 text-center font-weight-bold text-dark">UNDANGAN SEMINAR PROGRESS {{ undangan.id_tahapan }}</div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-2"><a style="font-size:14px">&nbsp;Hari, Tanggal</a><a class="float-right">:</a></div>
                                                <div class="col-10"><a class="text-dark" style="font-size:14px">{{ convertDay(undangan.tanggal) }}</a></div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-2"><a style="font-size:14px">&nbsp;Jam</a><a class="float-right">:</a></div>
                                                <div class="col-10"><a class="text-dark" style="font-size:14px">{{ convertTime(undangan.tanggal) }} WIB</a></div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-2"><a style="font-size:14px">&nbsp;Tempat</a><a class="float-right">:</a></div>
                                                <div class="col-10"><a class="text-dark" style="font-size:14px">T. Sipil - 3215</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-2"><a style="font-size:14px">&nbsp;Pengutamaan</a><a class="float-right">:</a></div>
                                                <div class="col-10"><a class="text-dark" style="font-size:14px">{{ undangan.pengutamaan }}</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-2"><a style="font-size:14px">&nbsp;Nama</a><a class="float-right">:</a></div>
                                                <div class="col-4"><a class="text-dark" style="font-size:14px">{{ undangan.name }}</a></div>
                                                <div class="col-2"><a style="border-left:1px black solid;font-size:14px">&nbsp;NIM</a><a class="float-right">:</a></div>
                                                <div class="col-4"><a class="text-dark" style="font-size:14px">{{ undangan.nim }}</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-3"><a style="font-size:14px">&nbsp;Judul Disertasi</a><a class="float-right">:</a></div>
                                                <div class="col-9"><a class="text-dark" style="font-size:14px">{{ undangan.judul }}</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-3"><a style="font-size:14px">&nbsp;Tim Penguji</a><a class="float-right">:</a></div>
                                                <div class="col-9"></div>
                                                <div class="col-7"><a class="text-dark" style="font-size:14px">&nbsp;{{ undangan.pembimbing[0] ? undangan.pembimbing[0] : '-' }}</a></div>
                                                <div class="col-5"><a class="text-dark" style="font-size:14px">(Pembimbing 1)</a></div>
                                                <div class="col-7"><a class="text-dark" style="font-size:14px">&nbsp;{{ undangan.pembimbing[1] ? undangan.pembimbing[1] : '-' }}</a></div>
                                                <div class="col-5"><a class="text-dark" style="font-size:14px">(Pembimbing 2)</a></div>
                                                <div class="col-7"><a class="text-dark" style="font-size:14px">&nbsp;{{ undangan.pembimbing[2] ? undangan.pembimbing[2] : '-' }}</a></div>
                                                <div class="col-5"><a class="text-dark" style="font-size:14px">(Pembimbing 3)</a></div>
                                                <div class="col-7"><a class="text-dark" style="font-size:14px">&nbsp;Ir. R. Muslinang Moestopo, M.S.EM, Ph.D.</a></div>
                                                <div class="col-5"><a class="text-dark" style="font-size:14px">(Anggota PPD)</a></div>
                                                <div class="col-7"><a class="text-dark" style="font-size:14px">&nbsp;Dhemi Harlan, Ph.D</a></div>
                                                <div class="col-5"><a class="text-dark" style="font-size:14px">(Koordinator Progress)</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-3"><a style="font-size:14px">&nbsp;Catatan</a><a class="float-right">:</a></div>
                                                <div class="col-9"></div>
                                                <div class="col-12"><a class="text-dark" style="font-size:14px">&nbsp;Handout materi presentasi mahasiswa harus sudah dibagikan kepada semua penguji paling lambat 
                                                3 hari <br>&nbsp;sebelum seminar dilakukan.</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="row mt-1">
                                                <div class="col-3"><a style="font-size:14px">&nbsp;Yang mengundang</a><a class="float-right">:</a></div>
                                                <div class="col-9"></div>
                                                <div class="col-12"><a class="text-dark" style="font-size:14px">&nbsp;Ketua Program Studi Magister & Doktor T. Sipil</a></div>
                                                &nbsp;&nbsp;<img style="margin-left:10px;width:30%" src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/0c74180ee5eda8d9ca3c12ae/Undangan%20Progress%20I%20Altho%20edit-1.png">
                                                <div class="col-12"><a class="text-dark" style="font-size:14px">&nbsp;Dr.-Ing. Ir. Ediansjah Zulkifli, S.T., M.T.</a></div>
                                            </div>
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                </section>
        </vue-html2pdf> 
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import moment from 'moment';
    import DatetimePicker from 'vuejs-datetimepicker';
    import VueHtml2pdf from 'vue-html2pdf'

    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Data Kemajuan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                printUrl: 'https://print.drei-system.com/',
                fileName: "Pengajuan Kemajuan Mahasiswa",
                header: "Pengajuan Data Kemajuan Mahasiswa",
                dataPengguna: [],
                dataTahapan: [],
                dataPengutamaan: [],
                detailTahapan: null,
                fetching: true,
                checkPassword: 0,
                filterTahapan: '',
                filterPengutamaan: '',
                form: {
                    id : '',
                    tanggal : '',
                    id_tahapan: '',
                    id_kemajuan: '',
                    undangan: '',
                    no_surat: '',
                },
                print: {
                    fullname: '',
                    username: '',
                    judul: '',
                    pembimbing: '',
                    studi_berjalan: '',
                    tahapan: '',
                    batas: '',
                    id_tahapan: '',
                    tanggal: '',
                },
                lulus: {
                    id : '',
                    tanggal : '',
                    id_tahapan: '',
                    id_kemajuan: '',
                    berita_acara: '',
                    sertifikat: '',
                },
                undangan: {
                    name: '',
                    nim: '',
                    judul: '',
                    tahapan: '',
                    pengutamaan: '',
                    id_tahapan: '',
                    tgl_undangan: '',
                    tanggal: '',
                    undangan: '',
                    pembimbing: ''
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 7,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
            this.getTahapan();
            this.getPengutamaan();
        },
        components: {
            vPagination,
            DatetimePicker,
            VueHtml2pdf
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            hide_lulus() {
                this.$modal.hide('my-lulus')
            },
            hide_sertifikat() {
                this.$modal.hide('my-sertifikat')
            },
            // Get data data periode
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/request/magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getTahapan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/tahapan-magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataTahapan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getPengutamaan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/pengutamaan-magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengutamaan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            updateSurat (id) {
                let uri = this.$apiconfig + 'mahasiswa/kemajuan/print-magister';
                this.$http.get(uri, {
                    params: {
                        id     : id,
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    this.print.fullname = getResponse.data.fullname;
                    this.print.username = getResponse.data.username;
                    this.print.judul = getResponse.data.judul;
                    this.print.pembimbing = getResponse.data.pembimbing[0] ? getResponse.data.pembimbing[0] : '';
                    this.print.tahapan = getResponse.data.name;
                    this.print.batas = getResponse.data.batas_studi;
                    this.print.studi_berjalan = getResponse.data.progress_studi;
                    this.print.id_tahapan = getResponse.data.id_tahapan;
                    this.print.tanggal = getResponse.data.created_at;
                });
            },

            generateReport () {
                this.$refs.html2Pdf.generatePdf()
            },

            // save periode
            submitForm() {
                const formData = new FormData();
                formData.append('file', this.form.undangan);
                // formData.append('no_surat', this.form.no_surat);
                formData.append('tanggal', this.form.tanggal);
                formData.append('id_tahapan', this.form.id_tahapan);
                formData.append('id_kemajuan', this.form.id_kemajuan);

                this.$http.post(this.baseUrl + 'admin/request/save-magister', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        
                        this.$modal.hide('my-modal')
                        this.getData()
                        this.form.id = ''
                        this.form.tanggal = ''
                        this.form.id_tahapan = ''
                        this.form.id_pengajuan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            submitLulus() {
                const formData = new FormData();
                formData.append('file', this.lulus.berita_acara);
                formData.append('id_tahapan', this.lulus.id_tahapan);
                formData.append('id_kemajuan', this.lulus.id_kemajuan);

                this.$http.post(this.baseUrl + 'admin/request/done-magister', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        
                        this.$modal.hide('my-lulus')
                        this.getData()
                        this.lulus.id = ''
                        this.lulus.tanggal = ''
                        this.lulus.id_tahapan = ''
                        this.lulus.id_pengajuan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            submitSertifikat() {
                const formData = new FormData();
                formData.append('file', this.lulus.sertifikat);
                formData.append('id_tahapan', this.lulus.id_tahapan);
                formData.append('id_kemajuan', this.lulus.id_kemajuan);

                this.$http.post(this.baseUrl + 'admin/request/sertifikat-magister', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        
                        this.$modal.hide('my-sertifikat')
                        this.getData()
                        this.lulus.id = ''
                        this.lulus.tanggal = ''
                        this.lulus.id_tahapan = ''
                        this.lulus.id_pengajuan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            // Edit data periode
            updateProgress(idTahapan, idKemajuan, tanggal) {
                this.form.id_tahapan = idTahapan;
                this.form.id_kemajuan = idKemajuan;
                this.form.tanggal = tanggal;
                this.$modal.show('my-modal');
            },
            updateLulus(idTahapan, idKemajuan, tanggal) {
                this.lulus.id_tahapan = idTahapan;
                this.lulus.id_kemajuan = idKemajuan;
                this.lulus.tanggal = tanggal;
                this.$modal.show('my-lulus');
            },
            updateSertifikat(idTahapan, idKemajuan, tanggal) {
                this.lulus.id_tahapan = idTahapan;
                this.lulus.id_kemajuan = idKemajuan;
                this.lulus.tanggal = tanggal;
                this.$modal.show('my-sertifikat');
            },
            lulusProgress(idTahapan, idKemajuan) {
                var fd = new FormData()
                fd.append('id_tahapan', idTahapan)
                fd.append('id_kemajuan', idKemajuan)
                this.$swal({
                    title: 'Apakah sudah lulus ?',
                    html: 'Progress Kemajuan akan berubah dan menambah riwayat',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Luluskan!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/request/lulus-magister',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status) {
                                this.$swal(
                                    'Success!',
                                    'Progress berhasil diperbaharui.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Failed!',
                                    'Progress gagal diperbaharui.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').lang("id").format('DD MMMM YYYY');
            },
            convertTime : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('hh:mm');
            },
            convertDay : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('dddd, DD MMMM YYYY');
            },
            onlyDate : function (date) {
                return moment(date, 'YYYY-MM-DD').lang("id").format('DD MMMM YYYY');
            },
            uploadFile() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.undangan = this.$refs.fileInput.files[0];
                }
            },
            uploadFileLulus() {
                const file = this.$refs.fileInputLulus.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 1) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.lulus.berita_acara = this.$refs.fileInputLulus.files[0];
                }
            },
            uploadFileSertifikat() {
                const file = this.$refs.fileInputSertifikat.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 1) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.lulus.sertifikat = this.$refs.fileInputSertifikat.files[0];
                }
            },
            generateUndangan () {
                this.$refs.html2PdfUndangan.generatePdf()
            },
            updateUndangan (id) {
                let uri = this.$apiconfig + 'mahasiswa/kemajuan/undangan-magister';
                this.$http.get(uri, {
                    params: {
                        id     : id,
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    this.undangan.name = getResponse.data.fullname;
                    this.undangan.nim = getResponse.data.username;
                    this.undangan.undangan = getResponse.data.undangan;
                    this.undangan.judul = getResponse.data.judul;
                    this.undangan.pembimbing = getResponse.data.pembimbing;
                    this.undangan.tahapan = getResponse.data.name;
                    this.undangan.pengutamaan = getResponse.data.pengutamaan;
                    this.undangan.id_tahapan = getResponse.data.id_tahapan;
                    this.undangan.tgl_undangan = getResponse.data.tgl_undangan;
                    this.undangan.tanggal = getResponse.data.tanggal;
                });
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'mahasiswa/kemajuan/delete-magister',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'Deleted!',
                                response.message,
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `admin/kemajuan/export-magister`, {
                    params: {
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                        keywords: this.keywords,
                    }
                });
                return response.data.data;
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>